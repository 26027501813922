@import url(https://fonts.googleapis.com/css2?family=Modak&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bangers&display=swap);
.main-container {
  height: calc(100vh - 145px);
  overflow-y: hidden;
  margin: 0px;
}

.playlist {
  height:  calc(100vh - 300px);
  margin-bottom: 10px;
  background-color: white; 
  overflow-y: scroll;
  margin-right: -10px;
  border-radius: 10px;
}

#playlit::-webkit-scrollbar {
  width: 20px;
}

#playlist::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

#playlist::-webkit-scrollbar-thumb {
  background: #B3BAB4; 
  border-radius: 10px;
}

#playlist::-webkit-scrollbar-thumb:hover {
  background: #3B413C; 
}

.results {
  height: calc(100vh - 330px);
  background-color: white; 
  overflow-y: scroll;
  margin-right: -10px;
  margin-bottom: 10px;
  margin-top: -25px;
  border-radius: 10px;
}

#results::-webkit-scrollbar {
  width: 20px;
}

#results::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

#results::-webkit-scrollbar-thumb {
  background: #B3BAB4; 
  border-radius: 10px;
}

#results::-webkit-scrollbar-thumb:hover {
  background: #3B413C; 
}

.App {
  text-align: center;
  padding: 0px;
  margin: 0px;
}

.App-container {
  height: calc(100vh - 145px);
  overflow-y: scroll;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.no-margin-padding {
  padding: 0px;
  margin: 0px;
}

@-webkit-keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


div, button {
  margin: 10px;
}

/* Site Scrollbar */

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  background: #B3BAB4; 
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #3B413C; 
}

/* Site Navbar */
.navbar-app-nav-bottom {
  background-color: #94D1BE;
  border: 2px solid #3B413C;
  height: 50px;
}

.nav-link-app-nav-link-bottom {
  font-size: 24px;
  color: #3B413C;
  margin-bottom: 10px;
}

.nav-link-app-nav-link-bottom:hover {
  color: pink;
}
/* Chatroom */
#chatRoom {
  background-color: white; 
  overflow-y: scroll;
  overflow-x: auto;
  margin-right: -10px;
  border-radius: 10px;
  height: calc(100vh - 300px);
  overflow-wrap: anywhere;
  text-align: left;
}

#chatRoom::-webkit-scrollbar {
  width: 20px;
}

#chatRoom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

#chatRoom::-webkit-scrollbar-thumb {
  background: #B3BAB4; 
  border-radius: 10px;
}

#chatRoom::-webkit-scrollbar-thumb:hover {
  background: #3B413C; 
}

.available-streams {
  height: calc(100vh - 225px);
}

.radio-main-col-menu {
  background-color: white;
  border-radius: 10px;
  height: calc(100vh - 166px);
}

.radio-main-col-watch {
  margin: 0;
  padding: 0;
  background-color: #3B413C;
  height: calc(100vh - 145px);
}

.radio-side-col {
  height: calc(100vh - 145px);
}
