.main-container {
  height: calc(100vh - 145px);
  overflow-y: hidden;
  margin: 0px;
}

.playlist {
  height:  calc(100vh - 300px);
  margin-bottom: 10px;
  background-color: white; 
  overflow-y: scroll;
  margin-right: -10px;
  border-radius: 10px;
}

#playlit::-webkit-scrollbar {
  width: 20px;
}

#playlist::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

#playlist::-webkit-scrollbar-thumb {
  background: #B3BAB4; 
  border-radius: 10px;
}

#playlist::-webkit-scrollbar-thumb:hover {
  background: #3B413C; 
}

.results {
  height: calc(100vh - 330px);
  background-color: white; 
  overflow-y: scroll;
  margin-right: -10px;
  margin-bottom: 10px;
  margin-top: -25px;
  border-radius: 10px;
}

#results::-webkit-scrollbar {
  width: 20px;
}

#results::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

#results::-webkit-scrollbar-thumb {
  background: #B3BAB4; 
  border-radius: 10px;
}

#results::-webkit-scrollbar-thumb:hover {
  background: #3B413C; 
}
