@import url('https://fonts.googleapis.com/css2?family=Modak&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');

.App {
  text-align: center;
  padding: 0px;
  margin: 0px;
}

.App-container {
  height: calc(100vh - 145px);
  overflow-y: scroll;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.no-margin-padding {
  padding: 0px;
  margin: 0px;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


div, button {
  margin: 10px;
}

/* Site Scrollbar */

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  background: #B3BAB4; 
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #3B413C; 
}

/* Site Navbar */
.navbar-app-nav-bottom {
  background-color: #94D1BE;
  border: 2px solid #3B413C;
  height: 50px;
}

.nav-link-app-nav-link-bottom {
  font-size: 24px;
  color: #3B413C;
  margin-bottom: 10px;
}

.nav-link-app-nav-link-bottom:hover {
  color: pink;
}