/* Chatroom */
#chatRoom {
  background-color: white; 
  overflow-y: scroll;
  overflow-x: auto;
  margin-right: -10px;
  border-radius: 10px;
  height: calc(100vh - 300px);
  overflow-wrap: anywhere;
  text-align: left;
}

#chatRoom::-webkit-scrollbar {
  width: 20px;
}

#chatRoom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

#chatRoom::-webkit-scrollbar-thumb {
  background: #B3BAB4; 
  border-radius: 10px;
}

#chatRoom::-webkit-scrollbar-thumb:hover {
  background: #3B413C; 
}

.available-streams {
  height: calc(100vh - 225px);
}

.radio-main-col-menu {
  background-color: white;
  border-radius: 10px;
  height: calc(100vh - 166px);
}

.radio-main-col-watch {
  margin: 0;
  padding: 0;
  background-color: #3B413C;
  height: calc(100vh - 145px);
}

.radio-side-col {
  height: calc(100vh - 145px);
}